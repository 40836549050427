import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Figure from "../molecules/Figure"
import ButtonGroup from "../molecules/ButtonGroup"
import TextReveal from "../reveals/TextReveal"

const TextImageBlock = ({ block }) => {
  let imageWidth = ""
  let textWidth = ""

  if (block.image_size === "big") {
    imageWidth = "w-full lg:w-2/3"
    textWidth = "lg:w-1/3"
  } else if (block.image_size === "small") {
    imageWidth = "w-2/3 sm:w-1/2 lg:w-1/3"
    textWidth = "lg:w-2/3"
  } else if (block.image_size === "extra-small") {
    imageWidth = "w-1/3 lg:w-1/6"
    textWidth = "lg:w-5/6"
  } else {
    imageWidth = "w-full lg:w-1/2"
    textWidth = "lg:w-1/2"
  }

  return (
    <BlockWrapper block={block}>
      <div
        className={`flex flex-row flex-wrap -mx-grid ${
          block.headline?.length > 0 ? "pt-8" : ""
        }`}
      >
        <div className={`relative ${imageWidth} px-grid`}>
          {block.image?.filename && (
            <Figure
              className="mb-4 lg:mb-0"
              image={block.image}
              caption={block.caption}
              aspectRatio={block.aspect_ratio}
              fitIn={block.image_fitin}
            />
          )}
        </div>
        <div
          className={`w-full mb-4 ${textWidth} px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          {/* <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
          /> */}
          <TextReveal>
            <Richtext text={block.text} />
          </TextReveal>
          <ButtonGroup className="mt-8" buttons={block.buttons} />
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextImageBlock

import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import ImageReveal from "../reveals/ImageReveal"

const Figure = ({
  className,
  image,
  caption,
  image_width = "container",
  aspectRatio,
  captionClass = "",
  fitIn = false,
}) => {
  return (
    <figure className={className}>
      <ImageReveal>
        <div className="relative overflow-hidden">
          <Image
            image={image}
            aspectRatio={aspectRatio}
            className="w-full h-auto"
            fitIn={fitIn}
          />
        </div>
      </ImageReveal>
      {((caption && caption.length > 0) ||
        image.title?.length > 0 ||
        image.copyright?.length > 0) && (
        <figcaption
          className={`w-full max-w-[960px] mx-auto mt-4 ${captionClass}`}
        >
          {caption && caption.length > 0 ? (
            <span className="mr-4">{caption}</span>
          ) : image.title?.length > 0 ? (
            <span className="mr-4">{image.title}</span>
          ) : (
            ""
          )}
          {image.copyright?.length > 0 && <small>© {image.copyright}</small>}
        </figcaption>
      )}
    </figure>
  )
}

export default Figure
